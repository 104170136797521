// src/components/AnimatedBackground.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './AnimatedBackground.css';

const AnimatedBackground = () => {
  const [positions, setPositions] = useState([
    generateRandomPosition(),
    generateRandomPosition(),
    generateRandomPosition(),
  ]);

  // Функция для генерации случайных позиций с учетом границ экрана
  function generateRandomPosition() {
    const blockWidth = 150; // Ширина блока
    const blockHeight = 100; // Высота блока

    const maxX = window.innerWidth - blockWidth; // Максимальная координата X
    const maxY = window.innerHeight - blockHeight; // Максимальная координата Y

    return {
      top: `${Math.random() * maxY}px`,  // Случайная позиция по Y
      left: `${Math.random() * maxX}px`, // Случайная позиция по X
    };
  }

  // Обновляем позицию блока после его исчезновения
  const handleAnimationComplete = (index) => {
    setPositions((prevPositions) => {
      const newPositions = [...prevPositions];
      newPositions[index] = generateRandomPosition();
      return newPositions;
    });
  };

  const blocks = [
    { content: '<div>Hello World!</div>', backgroundColor: 'rgba(0, 123, 255, 0.1)' },
    { content: 'const greet = () => \'Hello, Web Dev!\'', backgroundColor: 'rgba(255, 193, 7, 0.2)' },
    { content: '<button>Click Me</button>', backgroundColor: 'rgba(40, 167, 69, 0.2)' },
  ];

  return (
    <div className="animated-background">
      {blocks.map((block, index) => (
        <motion.div
          key={index}
          className="animated-block"
          style={{
            backgroundColor: block.backgroundColor,
            ...positions[index],
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 2, // Плавное появление/исчезновение за 2 секунды
            delay: Math.random() * 3, // Случайная задержка перед началом
          }}
          onAnimationComplete={(definition) => {
            if (definition === 'exit') {
              handleAnimationComplete(index); // Обновление позиции после исчезновения
            }
          }}
        >
          <code>{block.content}</code>
        </motion.div>
      ))}
    </div>
  );
};

export default AnimatedBackground;
