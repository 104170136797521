// src/components/ProjectsSection.js
import React from 'react';
import { useInView } from 'react-intersection-observer';
import './ProjectsSection.css';

const projects = [
  {
    title: 'Интернет-магазин на JavaScript',
    videoUrl: './e-commerce.mp4', 
    link: 'https://shop.elisaillustrator.ru/shop.html',
  },
  {
    title: 'Игра на React',
    videoUrl: './game.mp4',
    link: 'https://game.elisaillustrator.ru',
  },
  {
    title: 'Landing (HTML, CSS)',
    videoUrl: './landing.mp4',
    link: 'https://portfolio.elisaillustrator.ru',
  },
];

const ProjectsSection = () => {

  const { ref, inView } = useInView({
    triggerOnce: true, // Анимация будет срабатывать только один раз
    threshold: 0.2, // Процент видимой части элемента, при котором срабатывает анимация
  });


  return (
  <div ref={ref} className={`projects-sections ${inView ? 'visible' : ''}`}>
    <div className="projects-section">
      <h2>Мои проекты</h2>
      <div className="projects-grid">
        {projects.map((project, index) => (
          <a key={index} href={project.link} className="project-card">
            <div className="project-video">
              <video src={project.videoUrl} autoPlay loop muted />
            </div>
            <div className="project-info">
              <p>{project.title}</p>
            </div>
          </a>
        ))}
      </div>
    </div>
  </div>
  );
};

export default ProjectsSection;
