import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './footer.css';

const Footer = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        'service_portfolio', //  service ID из EmailJS
        '__ejs-test-mail-service__', //  template ID из EmailJS
        formData,
        'iKaqCAT2Nl9Q4is_2' // user ID из EmailJS
      )
      .then(
        (result) => {
          alert('Сообщение отправлено!');
          setFormData({ name: '', email: '', message: '' }); 
        },
        (error) => {
          alert('Ошибка при отправке, попробуйте еще раз.');
        }
      );
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <h2>Связаться со мной</h2>
        <p>Оставьте ваше сообщение, и я свяжусь с вами как можно скорее.</p>
        <form className="contact-form" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Ваше имя"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <input
            type="email"
            placeholder="e-mail"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <textarea
            placeholder="Ваше сообщение"
            name="message"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
          <button type="submit">Отправить</button>
        </form>
      </div>
      <div className="footer-bottom">
        <p>© 2024 Dobrovanov Все права защищены.</p>
      </div>
    </footer>
  );
};

export default Footer;
