// src/components/ProjectDetails.js
import React from 'react';
import { useParams } from 'react-router-dom';
// import './ProjectDetails.css';

const ProjectDetails = ({ projectId }) => {
  const projectInfo = {
    shop: {
      title: 'Интернет-магазин на JavaScript',
      description: 'Описание интернет-магазина...',
    },
    game: {
      title: 'Игра на React',
      description: 'Описание игры...',
    },
    portfolio: {
      title: 'Landing (HTML, CSS)',
      description: 'Описание лендинга...',
    },
  };

  const project = projectInfo[projectId];

  return (
    <div className="project-details">
      <h2>{project.title}</h2>
      <p>{project.description}</p>
    </div>
  );
};

export default ProjectDetails;
