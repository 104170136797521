// src/components/SocialLinks.js
import React from 'react';
import { FaEnvelope, FaTelegramPlane, FaGithub } from 'react-icons/fa';
import './SocialLinks.css'; // Создайте файл стилей

const socialLinks = [
  {
    name: 'Gmail',
    url: 'mailto:vdobrovanov95@gmail.com',
    icon: <FaEnvelope />,
  },
  {
    name: 'Telegram',
    url: 'https://t.me/Dobrovanov_v',
    icon: <FaTelegramPlane />,
  },
  {
    name: 'GitHub',
    url: 'https://github.com/VladimirDobrovanov',
    icon: <FaGithub />,
  },
];

const SocialLinks = () => {
  return (
    <div className="social-links">
      {socialLinks.map((link, index) => (
        <a key={index} href={link.url} target="_blank" rel="noopener noreferrer" className="social-link">
          <div className="social-icon">{link.icon}</div>
          <div className="social-info">
            <span className="social-name">{link.name}</span>
            <span className="social-url">{link.url}</span>
          </div>
        </a>
      ))}
    </div>
  );
};

export default SocialLinks;
