// src/components/MainContent.js
import React from 'react';
import './MainContent.css';
import ImageSlider from './ImageSlider';
import SocialLinks from './SocialLinks';
import AnimatedBackground from './AnimatedBackground';





const MainContent = () => {
  return (
  <div className="main-content-wrapper">
    <main className="main-content">
      <div className="sidebar">
      <ImageSlider />
        <h2 className="profile-h2">Доброванов Владимир</h2>
        <p>Front-end developer</p>
        <ul className="tech-stack">
          <li>HTML</li>
          <li>css</li>
          <li>JavaScript</li>
          <li>React</li>
          <li>npm</li>
          <li>git</li>
        </ul>
        <SocialLinks />
      </div>
      <div className="content-area">
      <AnimatedBackground />
        <h1 className="profile-h1">Добро пожаловать на мой сайт-портфолио!</h1>
        <p className="profile-p">
          Меня зовут Доброванов Владимир, я занимаюсь разработкой веб-приложений.
          На этом сайте вы найдете информацию о моих навыках и проектах.
        </p>
      </div>
    </main>
  </div>
  );
};

export default MainContent;
