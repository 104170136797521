// src/components/ImageSlider.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './ImageSlider.css'; // Создайте файл для стилей

const images = [
  './photo.jpg', // Замените на реальные URL фотографий
  './photo2.jpg',
  './photo3.jpg',
];

const ImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="image-slider">
        <div className="slider-controls">
        <button onClick={prevImage} className="arrow-button">❮</button>
        <button onClick={nextImage} className="arrow-button">❯</button>
        </div>
      <motion.img
        key={currentIndex}
        src={images[currentIndex]}
        alt="Profile"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }} // Плавное растворение за 1 секунду
      />
    </div>
  );
};

export default ImageSlider;
