// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import MainContent from './MainContent';
import ProjectsSection from './ProjectsSection';
import ProjectDetails from './ProjectDetails'; // Создайте этот компонент для отображения страницы проекта
import SkillsSection from './progressbar';


function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <MainContent />
        <ProjectsSection />
        <Routes>
          <Route path="/project/shop" element={<ProjectDetails projectId="shop" />} />
          <Route path="/project/game" element={<ProjectDetails projectId="game" />} />
          <Route path="/project/portfolio" element={<ProjectDetails projectId="portfolio" />} />
        </Routes>
        <SkillsSection />
      </div>
    </Router>
  );
}

export default App;
