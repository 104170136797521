import React, { useState } from 'react';
import './Header.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <a href='https.//dobrovanov.ru' className="logo">dobrovanov.ru</a>
      <nav className={`nav ${isMenuOpen ? 'open' : ''}`}>
        <a href="#home">Главная</a>
        <a href="#skills">Навыки</a>
        <a href="#projects">Проекты</a>
        <a href="#contact">Связаться</a>
      </nav>
      <div className="burger" onClick={toggleMenu}>
        <div className={`line ${isMenuOpen ? 'open' : ''}`}></div>
        <div className={`line ${isMenuOpen ? 'open' : ''}`}></div>
        <div className={`line ${isMenuOpen ? 'open' : ''}`}></div>
      </div>
    </header>
  );
};

export default Header;
