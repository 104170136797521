import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './progressbar.css'

const skillsData = [
  { name: 'HTML & CSS', value: 80, color: '#f06529' },
  { name: 'JavaScript', value: 55, color: '#f7df1e' },
  { name: 'React jsx', value: 30, color: '#61dafb' },
  { name: 'Soft Skills', value: 99, color: '#34c759' },
];

const SkillsSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector('.skills-section');
      const sectionTop = section.getBoundingClientRect().top;
      const triggerPoint = window.innerHeight / 3.0;

      if (sectionTop < triggerPoint) {
        setIsVisible(true);
        window.removeEventListener('scroll', handleScroll);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="skills-section">
      <h2>Мои навыки</h2>
      <div className="skills-grid">
        {skillsData.map((skill, index) => (
          <div key={index} className="skill-item">
            <CircularProgressbar
              value={isVisible ? skill.value : 0}
              text={`${skill.value}%`}
              styles={buildStyles({
                pathColor: skill.color,
                textColor: '#fff',
                trailColor: '#444',
                backgroundColor: '#282c34',
              })}
            />
            <p>{skill.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkillsSection;
